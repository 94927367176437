import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useToast from '../../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../../state/store/reducers/ui/headerSlice';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { FormInput, FormTextArea, RadioGroupInput } from '../../../../../../../components/form';
import { Button } from '../../../../../../../components/common';


function CreateModulePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { moduleId } = useParams();
    const { addToast } = useToast();

    const [module, setModule] = useState<any>(null);


    const saveMutation = useMutation({
        /*  mutationFn: (data: any) => {
             return feature ? candidatesService.update(candidate.id, data) : candidatesService.create(data);
         } */
    });


    useEffect(() => {
        dispatch(changePageInfo({ title: moduleId ? "Edit Module" : "Create Module" }));

        if (moduleId) {
            if (location.state && location.state.module) {
                setModule(location.state.module);
            } else {
                // fetch module;
            }
        }
    }, []);



    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        saveMutation.mutate(formData, {
            onSuccess: (data) => {
                addToast({
                    text: `Module ${moduleId ? 'updated' : 'added'} successfully`,
                    type: 'success'
                });

                navigate('/subscriptions/modules');
            },
            onError: () => {
            }
        });
    }


    return (
        <div>
            <Formik
                initialValues={{
                    name: module?.name || '',
                    description: module?.description || '',
                    status: module?.status || 'ACTIVE',
                    featureIds: module?.featureIds || [{ id: '' }],
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                validateOnMount
                enableReinitialize
            >
                {({ values, errors, touched, setErrors, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid, isInitialValid }) => (
                    <Form className="">
                        {
                            saveMutation.error &&
                            <div className='inline-block bg-red-500/5 rounded-lg px-4 py-2 mb-4'>
                                <p className='text-red-500'>{saveMutation.error as unknown as string}</p>
                            </div>
                        }
                        <div className='grid md:grid-cols-8 gap-8 items-start'>
                            <div className='md:col-span-4 bg-white dark:bg-gray-800 shadow-md dark:shadow-none p-10 rounded-xl'>
                                <div className='divide-y divide-gray-200 dark:divide-gray-700 mb-10'>
                                    <div className='pb-8'>
                                        <div className="mb-8">
                                            <label htmlFor="input-name" className='block text-sm mb-2'>Feature Name</label>
                                            <FormInput type="text" name="name" id="input-name" className="w-full" placeholder="Enter feature name" />
                                            {touched['name'] && errors['name'] && <p className='text-sm text-red-500 mt-2'>Feature name is Required</p>}
                                        </div>


                                        <div className="mb-8">
                                            <label htmlFor="input-description" className='block text-sm mb-2'>Description</label>
                                            <FormTextArea name='description' id="input-description" className='w-full' placeholder="Type here" rows={4} />
                                            {touched['description'] && errors['description'] && <p className='text-sm text-red-500 mt-2'>Description is Required</p>}
                                        </div>


                                        <div className=''>
                                            <label htmlFor="" className='block mb-2'>Status</label>
                                            <RadioGroupInput
                                                value={values['status']}
                                                options={[
                                                    { label: "Active", value: 'ACTIVE' },
                                                    { label: "Inactive", value: 'INACTIVE' },
                                                ]}
                                                onChange={(value) => setFieldValue('status', value)}
                                            />
                                        </div>
                                    </div>


                                    <div className='pt-8'>
                                        <p className='mb-2'>Features</p>

                                        <div className=''>
                                            <FieldArray
                                                name="featureIds"
                                                render={arrayHelpers => (
                                                    <React.Fragment>
                                                        {
                                                            values.featureIds.map((feature: any, featureIndex: number) =>
                                                                <div key={feature?.id || `invoice-item-${featureIndex}`} className='grid grid-cols-6 gap-x-3 mb-4'>
                                                                    <div className='col-span-5'>

                                                                    </div>

                                                                    <div>
                                                                        {
                                                                            featureIndex > 0 &&
                                                                            <button type='button' onClick={() => arrayHelpers.remove(featureIndex)} className='hocus:text-red-500' title='Remove Item'>Remove</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }


                                                        <Button
                                                            type='button'
                                                            onClick={() => arrayHelpers.push({ id: null })}
                                                            className='flex items-center gap-1.5 text-blue  bg-black/5 hocus:bg-black/10  dark:bg-white/5 dark:hocus:bg-white/10 px-4 py-2.5'
                                                        >
                                                            <i className="ri-add-fill"></i>
                                                            <span>Add Another Feature</span>
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className='flex justify-end items-center mt-6'>
                                    <Button type='submit' loading={saveMutation.isPending} className='' disabled={saveMutation.isPending}>
                                        {module ? "Save" : "Create Module"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}


const featuresValidationSchema = yup.object().shape({
    id: yup.number().integer().required().label(""),
});

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    description: yup.string().trim().required(),
    status: yup.string().trim().required(),
    //featureIds: yup.array().min(1).required(),
    featureIds: yup.array().of(featuresValidationSchema).required().min(1),
});

export default CreateModulePage;