import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import useAlert from '../../../../../../../state/hooks/useAlert';
import useToast from '../../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../../state/store/reducers/ui/headerSlice';
import { LinkButton, Pagination } from '../../../../../../../components/common';
import SubscriptionPlanListFilters from './SubscriptionPlanListFilters';
import { useMutation, useQuery } from '@tanstack/react-query';
import subscriptionPlansService from '../../../../../../../services/api/subscriptions/subscriptionPlansService';
import { ListState } from '../../../../../../../components/blocks';
import { format } from 'date-fns';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../../../utils/pagination';
import ModalPlanDetails from '../../modals/ModalPlanDetails';
import ModalAttachSchoolToPlan from '../../modals/ModalAttachSchoolToPlan';


function SubscriptionPlanListPage() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    const [planDetails, setPlanDetails] = useState({ open: false, plan: null });
    const [attachSchool, setAttachSchool] = useState({ open: false, plan: null, action: '' });


    // get query params
    const query: any = {
        pager: {
            page: Number(searchParams.get('page')) || 1,
        },
        filters: {},
    };
    if (searchParams.get('name')) { query.filters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('status')) { query.filters['status'] = searchParams.get('status') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "Subscription Plans" }));
    }, []);


    const queryKey = ['subscriptions', 'plans', query];
    //const queryClient = useQueryClient();
    const plansQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => subscriptionPlansService.getPlans({ ...query.pager, ...query.filters }),
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const deleteMutation = useMutation({ mutationFn: (id: number) => subscriptionPlansService.deletePlan(id) });
    const handleDelete = (plan: any) => {
        openAlertConfirm({
            title: 'Delete Subscription Plan',
            message: `Are you sure you want to delete ${plan.name} subscription plan?`,
            onConfirmed: () => {
                deleteMutation.mutate(plan.id, {
                    onSuccess: () => {
                        addToast({
                            text: "Subscription plan deleted successfully",
                            type: "success",
                        });
                        setSearchParams(current => ({
                            ...Object.fromEntries(current.entries()),
                            page: '1',
                        }));
                        plansQuery.refetch();
                    },
                    onError: () => {
                        addToast({
                            text: "Subscription plan could not be deleted",
                            type: "error",
                        });
                    }
                });
            }
        })
    }


    return (
        <div>
            <div className='flex flex-row-reverse flex-wrap justify-between items-start mb-1'>
                <LinkButton to="/subscriptions/plans/create" >Create Subscription Plan</LinkButton>

                <SubscriptionPlanListFilters
                    initialData={query.filters}
                    onSearch={(values) => {
                        //setSearchParams({ ...values });
                        setSearchParams(current => {
                            const entries = { ...Object.fromEntries(current.entries()), ...values };
                            if (entries.page) {
                                entries.page = '1';
                            }
                            return entries;
                        });
                    }}
                    searching={plansQuery.isLoading}
                />
            </div>


            {
                !plansQuery.isError && plansQuery.isFetched && plansQuery.isFetched && plansQuery.data?.data && plansQuery.data?.data.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Name</th>
                                    <th className='py-6 px-6'>Description</th>
                                    <th className='py-6 px-6'>Target</th>
                                    <th className='py-6 px-6'>Frequency</th>
                                    <th className='py-6 px-6'>Price</th>
                                    <th className='py-6 px-6'>Active Subscriptions</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Date Created</th>
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    plansQuery.data?.data.map((planItem: any) =>
                                        <tr key={planItem.id}>
                                            <td className='py-4 px-6'>
                                                <button type='button' onClick={() => setPlanDetails({ open: true, plan: planItem })} className='transition-colors duration-300 text-left hocus:text-blue'>
                                                    {planItem.name}
                                                </button>
                                            </td>
                                            <td className='py-4 px-6' title={planItem.description}>
                                                <p className='line-clamp-2'>{planItem.description}</p>
                                            </td>
                                            <td className='py-4 px-6'>{planItem.target}</td>
                                            <td className='py-4 px-6'>{planItem.defaultFrequency}</td>
                                            <td className='py-4 px-6'>{planItem.defaultCurrency} {planItem?.defaultPrice?.toFixed(2)}</td>
                                            <td className='py-4 px-6'>{planItem.activeSubscriptionCount} / {planItem.subscriptionCount}</td>
                                            <td className='py-4 px-6'>
                                                <span className={`inline-block font-medium text-sm uppercase ${planItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>
                                                    {planItem.status || "Inactive"}
                                                </span>
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(planItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            <Menu.Item as="button" type='button' onClick={() => setPlanDetails({ open: true, plan: planItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View
                                                            </Menu.Item>
                                                            {
                                                                //planItem.target === 'SCHOOL' &&
                                                                <React.Fragment>
                                                                    <Menu.Item as="button" type='button' onClick={() => setAttachSchool({ open: true, plan: planItem, action: 'ATTACH' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Attach Schools
                                                                    </Menu.Item>
                                                                    <Menu.Item as="button" type='button' onClick={() => setAttachSchool({ open: true, plan: planItem, action: 'DETACH' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Detach Schools
                                                                    </Menu.Item>
                                                                </React.Fragment>
                                                            }
                                                            <Link to={`/subscriptions/plans/${planItem.id}`} state={{ module: planItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Edit
                                                            </Link>

                                                            {/* <Menu.Item as="button" type='button' onClick={() => setModuleForm({ open: true, module: planItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Enroll School
                                                            </Menu.Item>  */}
                                                            <Menu.Item as="button" type='button' onClick={() => handleDelete(planItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                Delete
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(plansQuery.data.pager.pageSize, plansQuery.data.pager.totalCount, plansQuery.data?.pager.page)}</p>

                        <Pagination
                            currentPage={plansQuery.data?.pager.page}
                            totalCount={plansQuery.data?.pager.totalCount}
                            pageSize={plansQuery.data.pager.pageSize}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }


            <ListState
                hasSearch={Object.keys(query.filters).length > 0}
                listCount={plansQuery.data?.data.length || 0}
                totalDataCount={plansQuery.data?.pager.total || 0}
                isLoading={plansQuery.isLoading}
                isError={plansQuery.isError}
                onTryAgain={() => plansQuery.refetch()}
            />

            <ModalPlanDetails
                open={planDetails.open}
                plan={planDetails.plan}
                onClose={() => setPlanDetails((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalAttachSchoolToPlan
                open={attachSchool.open}
                plan={attachSchool.plan}
                action={attachSchool.action as any}
                onClose={() => setAttachSchool((prevState) => ({ ...prevState, open: false }))}
                onAttached={(data: any) => {
                    addToast({
                        text: `${data.name} attached successfully.`,
                        type: "success"
                    });
                }}
                onDetached={(data: any) => {
                    addToast({
                        text: `${data.name} detached successfully.`,
                        type: "success"
                    });
                }}
            />
        </div>
    );
}


export default SubscriptionPlanListPage;