import React, { useState } from 'react';
import { Button, Pagination } from '../../../../../../components/common';
import ThirdPartyWebhookFormModal from '../modals/ThirdPartyWebhookFormModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import webhookService from '../../../../../../services/api/payments/webhookService';
import { ListState } from '../../../../../../components/blocks';
import { format } from 'date-fns';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import { paginationInfo } from '../../../../../../utils/pagination';
import useToast from '../../../../../../state/hooks/useToast';


function ThirdPartyWebhooksManagementTab({ selected, thirdParty }: Props) {
    const [hookForm, setHookForm] = useState<{ open: boolean; webhook: any | null }>({ open: false, webhook: null });
    const [page, setPage] = useState(1);

    const { addToast } = useToast();


    const query = { page }
    const queryKey = ['payments', 'third-paries', 'webhooks', query];
    const queryClient = useQueryClient();
    const webhooksQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => webhookService.getWebhooks(thirdParty?.apiKey, query),
        enabled: thirdParty?.apiKey && selected,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const statusMutation = useMutation({
        mutationFn: ({ id, ...statusObj }: any) => webhookService.updateStatus(id, thirdParty?.apiKey, statusObj),
        onSuccess: (data) => {
            addToast({
                text: `Web hook ${data?.status} successfully`,
                type: 'success',
            });

            queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                if (oldData) {
                    const index = oldData.data.findIndex((item: any) => item.id === data.id);
                    const newData = JSON.parse(JSON.stringify(oldData));
                    newData.data[index] = data;
                    return newData;
                }
            });
        },
        onError: (_, data) => {
            addToast({
                text: `Sorry, could not ${data?.status} third party`,
                type: 'error',
            });
        }
    });


    return (
        <React.Fragment>
            <div>
                <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                    <Button type='button' onClick={() => setHookForm({ open: true, webhook: null })} className='mb-4'>Create Webhook</Button>
                </div>

                {
                    // if there're third parties
                    !webhooksQuery.isError && webhooksQuery.isFetched && webhooksQuery.isFetched && webhooksQuery.data?.data && webhooksQuery.data?.data.length > 0 &&
                    <React.Fragment>
                        <div className='max-w-full overflow-x-auto mb-6'>
                            <table className="border-collapse table-auto w-full">
                                <thead>
                                    <tr className='text-left border-b-2 dark:border-gray-700'>
                                        <th className='py-6 px-6'>Entity</th>
                                        <th className='py-6 px-6'>Events</th>
                                        <th className='py-6 px-6'>Url</th>
                                        <th className='py-6 px-6'>Status</th>
                                        <th className='py-6 px-6'>Date Created</th>
                                        <th className='py-6 px-6'>Last Execution Date</th>
                                        <th className='py-6 px-6'>Last Execution Response Code</th>
                                        <th className='py-6 px-6 text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y dark:divide-gray-700/60">
                                    {
                                        webhooksQuery.data?.data.map((dataItem: any) =>
                                            <tr key={dataItem?.id}>
                                                <td className='py-4 px-6'>{dataItem?.entity}</td>
                                                <td className='py-4 px-6'>{dataItem?.events?.join(', ')}</td>
                                                <td className='py-4 px-6'>{dataItem?.url}</td>
                                                <td className='py-4 px-6'>
                                                    {
                                                        dataItem?.status === "ACTIVE" &&
                                                        <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{dataItem?.status}</span>
                                                    }
                                                    {
                                                        dataItem?.status !== "ACTIVE" && dataItem?.status !== "SUSPENDED" &&
                                                        <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{dataItem?.status}</span>
                                                    }
                                                    {
                                                        dataItem?.status === "SUSPENDED" &&
                                                        <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{dataItem?.status}</span>
                                                    }
                                                </td>
                                                <td className='py-4 px-6 whitespace-nowrap'>
                                                    {dataItem?.createdDate ? format(new Date(dataItem?.createdDate), 'd LLL yyyy h:mm a') : 'N/A'}
                                                </td>
                                                <td className='py-4 px-6 whitespace-nowrap'>
                                                    {dataItem?.lastExecutionDate ? format(new Date(dataItem?.lastExecutionDate), 'd LLL yyyy h:mm a') : 'N/A'}
                                                </td>
                                                <td className='py-4 px-6'>{dataItem?.lastExecutionResponseStatusCode}</td>
                                                <td className='py-4 px-6 text-right'>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <PopoverWithFloat placement='bottom-end' portal>
                                                            <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                                <i className="ri-more-2-fill"></i>
                                                            </Menu.Button>

                                                            <Menu.Items as='div' unmount={false} className="w-48 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                {
                                                                    (dataItem?.status === 'INACTIVE' || dataItem?.status === 'SUSPENDED') &&
                                                                    <Menu.Item as="button" type='button' onClick={() => statusMutation.mutate({ id: dataItem?.id, status: 'ACTIVE' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Activate
                                                                    </Menu.Item>
                                                                }
                                                                {
                                                                    dataItem?.status === 'ACTIVE' &&
                                                                    <Menu.Item as="button" type='button' onClick={() => statusMutation.mutate({ id: dataItem?.id, status: 'SUSPENDED' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                        Suspend
                                                                    </Menu.Item>
                                                                }
                                                            </Menu.Items>
                                                        </PopoverWithFloat>
                                                    </Menu>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='flex justify-between flex-wrap'>
                            <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(webhooksQuery.data.pager.pageSize, webhooksQuery.data.pager.totalCount, webhooksQuery.data?.pager.page)}</p>

                            <Pagination
                                currentPage={webhooksQuery.data?.pager.page}
                                totalCount={webhooksQuery.data?.pager.totalCount}
                                pageSize={webhooksQuery.data.pager.pageSize}
                                onPageChange={(page) => setPage(page)}
                            />
                        </div>
                    </React.Fragment>
                }



                <ListState
                    hasSearch={false}
                    listCount={webhooksQuery.data?.data.length || 0}
                    totalDataCount={webhooksQuery.data?.pager.total || 0}
                    isLoading={webhooksQuery.isLoading}
                    isError={webhooksQuery.isError}
                    onTryAgain={() => webhooksQuery.refetch()}
                />
            </div>



            <ThirdPartyWebhookFormModal
                open={hookForm.open}
                thirdParty={thirdParty}
                webhook={hookForm.webhook}
                onAdded={() => {
                    queryClient.invalidateQueries({ queryKey: ['payments', 'third-paries', 'webhooks'] });
                    addToast({
                        text: `Module created successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setHookForm(prevState => ({ ...prevState, open: false }))}
            />
        </React.Fragment>
    );
}


interface Props {
    selected: boolean;
    thirdParty: any;
}


export default ThirdPartyWebhooksManagementTab;