import { paymentsApi } from "../httpService";
import axios, { } from "axios";


const featureService = {
    getFeature: async (moduleId: number, query: object): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/modules/${moduleId}/features`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },



    createFeature: async (data: object): Promise<any> => {
        try {
            const result = await paymentsApi.post('/api/v1/features', data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },



    updateFeature: async (id: number, data: any): Promise<any> => {
        try {
            const result = await paymentsApi.put(`/api/v1/features/${id}`, data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }
            return new Promise((_, reject) => reject('An unexpected Error'));
        }
    },



    deleteFeature: async (moduleId: number, query: object = {}): Promise<any> => {
        try {
            const result = await paymentsApi.delete(`/api/v1/modules/${moduleId}/features`, {
                params: query
            });
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },
};


export default featureService;