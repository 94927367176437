import { paymentsApi } from '../httpService';


const getConfiguration = async (schoolId: any) => {
    try {
        const result = await paymentsApi.get(`/api/v1/clients/${schoolId}/configurations`, {});
        return {
            success: true,
            data: result.data.data,
            pager: result.data.pager
        };
    } catch (error: any) {
        /* if (error.response && error.response.status === 404) {
            return {
                success: false,
                notFound: true,
                message: "An unexpected Error"
            };
        } */

        return {
            success: false,
            notFound: false,
            message: "An unexpected Error"
        };
    }
}



const updateConfiguration = async (data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/configurations/payment-authorizations`, data, { timeout: 50000 });
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating authorization requirements. Please try again later."
        };
    }
}


const updateNegativeTransactions = async (data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/configurations/negative-transaction`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating configurations. Please try again later."
        };
    }
}



const updateMaxNegativeTransactionsThreshold = async (data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/configurations/max-negative-transaction-limit`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating configurations. Please try again later."
        };
    }
}



const updateOfflineTransactionAccess = async (data: any) => {
    try {
        const result = await paymentsApi.patch(`/api/v1/configurations/offline-payment`, data);
        return {
            success: true,
            data: result.data.data,
            message: ''
        };
    } catch (error: any) {

        return {
            success: false,
            data: null,
            message: "Sorry, there was a problem updating configurations. Please try again later."
        };
    }
}



export default {
    getConfiguration,
    updateConfiguration,
    updateNegativeTransactions,
    updateMaxNegativeTransactionsThreshold,
    updateOfflineTransactionAccess,
}