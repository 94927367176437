import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Switch } from '@headlessui/react';
import * as Yup from 'yup';

import Modal from '../../../../../../../../components/modals/Modal';
import { Button } from '../../../../../../../../components/common';
import configurationService from '../../../../../../../../services/api/payments/configurationService';
import useAlert from '../../../../../../../../state/hooks/useAlert';
import { TagInput } from '../../../../../../../../components/form';



function ModalOfflineAccessConfiguration({ isOpen, client, school, paymentConfig, onClose, onConfigChanged }: Props) {
    const [submitStatus, setSubmitStatus] = useState({ loading: false, error: '' });
    const { openAlertConfirm } = useAlert();


    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData, clientId: client.id };

        openAlertConfirm({
            title: 'Confirm Changes',
            message: `Are you sure you want to changes to who can record Offline Transactions for ${client.name}?`,
            onConfirmed: () => handleSave(payload, resetForm),
            positiveButtonText: "Save Changes",
            negativeButtonText: 'Cancel',
        });
    }

    const handleSave = async (payload: any, resetForm: () => void) => {
        setSubmitStatus({ loading: true, error: '' });
        if (payload.allowOfflinePaymentByEmployees) {
            payload.allowOfflinePaymentForSpecificEmployeeEmails = [];
        }

        const result = await configurationService.updateOfflineTransactionAccess(payload);
        if (result.success) {
            if (onConfigChanged) {
                onConfigChanged({ ...result.data });
            }
            //resetForm();
            onClose();
            setSubmitStatus({ loading: false, error: '' });
        } else {
            setSubmitStatus({ loading: false, error: result.message });
        }
    }


    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-xl">
                <div className="flex justify-between mb-6 px-10 pt-8">
                    <div className='mr-5'>
                        <h2 className="font-medium text-xl">
                            Offline Transaction Access
                        </h2>
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            allowOfflinePaymentByEmployees: paymentConfig?.allowOfflinePaymentByEmployees,
                            allowOfflinePaymentForSpecificEmployeeEmails: paymentConfig?.allowOfflinePaymentForSpecificEmployeeEmails || [],
                        }}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    submitStatus.error && !submitStatus.loading &&
                                    <p className='text-red-500 mb-4' >{submitStatus.error}</p>
                                }


                                <div className='mb-6'>
                                    <div className='flex justify-between items-center bg-gray-100 dark:bg-white/5 gap-x-5 gap-y-1 rounded-lg py-3.5 px-4'>
                                        <div className=''>
                                            <p className='font-medium'>Allow offline transactions for all employees</p>
                                        </div>

                                        <Switch
                                            checked={values["allowOfflinePaymentByEmployees"]}
                                            onChange={(on: boolean) => setFieldValue("allowOfflinePaymentByEmployees", on)}
                                            className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                        >
                                            <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                        </Switch>
                                    </div>
                                </div>


                                {
                                    !values["allowOfflinePaymentByEmployees"] &&
                                    <div className="mb-8">
                                        <label htmlFor="modal-input-specific" className='block mb-2'>Allow for Specific Employees</label>
                                        <TagInput value={values['allowOfflinePaymentForSpecificEmployeeEmails']} onChange={(tags) => setFieldValue('allowOfflinePaymentForSpecificEmployeeEmails', tags)} />
                                        {touched['allowOfflinePaymentForSpecificEmployeeEmails'] && errors['allowOfflinePaymentForSpecificEmployeeEmails'] && <p className='text-sm text-red-500 mt-2'>{errors['allowOfflinePaymentForSpecificEmployeeEmails'] as string}</p>}

                                        {/* <FormInput type="text" name="firstName" id="modal-input-first-name" className="w-full" placeholder="Enter first name" /> */}
                                    </div>
                                }

                                <div className='flex justify-end items-center mt-10'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    client: any;
    school: any;
    paymentConfig: any;
    onClose: () => void;
    onConfigChanged?: (paymentConfig: any) => void;
}


const validationSchema = Yup.object().shape({
    allowOfflinePaymentForSpecificEmployeeEmails: Yup.array().of(
        Yup.string().email('Invalid email address provided')
    ).min(0)
    //.required('At least one email is required')
    //.min(1, 'At least one email is required')
});

export default ModalOfflineAccessConfiguration;