import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

function FormError({ children, className }: Props) {
    return (
        <p className={twMerge('text-sm text-red-500 mt-2', className)}>{children}</p>
    );
}

interface Props extends PropsWithChildren {
    className?: string;
}

export default FormError;