import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import webhookService from '../../../../../../services/api/payments/webhookService';
import Modal from '../../../../../../components/modals/Modal';
import { Button } from '../../../../../../components/common';
import { FormInput, MultiSelectInput, SelectInput } from '../../../../../../components/form';
import regexStrings from '../../../../../../config/regexStrings';


function ThirdPartyWebhookFormModal({ open, thirdParty, webhook, onClose, onAdded, onUpdated }: Props) {
    useEffect(() => {
        if (open) {
            saveMutation.reset();
        }
    }, [open]);


    const saveMutation = useMutation({
        mutationFn: (data: any) => webhookService.createWebhook(thirdParty?.apiKey, data)
    });


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        saveMutation.mutate({ ...formData, events: formData.events.map((item: any) => item.value) }, {
            onSuccess: (data) => {
                onClose();
                if (webhook && onUpdated) {
                    onUpdated(data);
                }

                if (!webhook && onAdded) {
                    onAdded(data);
                }
            },
            onError: () => {
            }
        });
    }


    return (
        <Modal open={open} onClose={false ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {webhook ? "Edit Webhook" : "Add Webhook"}
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={saveMutation.isPending}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <Formik
                        initialValues={{
                            entity: webhook?.entity || '',
                            events: webhook?.events || [],
                            url: webhook?.url || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                            <Form className="">
                                {
                                    saveMutation.error &&
                                    <p className='text-red-500 mb-4'>{saveMutation.error as unknown as string}</p>
                                }


                                <div className="mb-6">
                                    <label htmlFor="modal-select-entity" className='block text-sm mb-2'>Entity</label>
                                    <SelectInput
                                        items={entityList}
                                        placeholder="Select entity"
                                        value={values["entity"]}
                                        onChange={(selected) => setFieldValue("entity", selected.value)}
                                        onBlur={() => setFieldTouched('entity', true)}
                                    />
                                    {touched['entity'] && errors['entity'] && <p className='text-sm text-red-500 mt-2'>Entity is required</p>}
                                </div>


                                <div className="mb-6">
                                    <label htmlFor="modal-select-event" className='block text-sm mb-2'>Events</label>
                                    <MultiSelectInput
                                        items={eventList}
                                        placeholder="Select events"
                                        value={values["events"]}
                                        onChange={(selected) => setFieldValue("events", selected)}
                                        onBlur={() => setFieldTouched('events', true)}
                                        searchable={false}
                                    />
                                    {touched['events'] && errors['events'] && <p className='text-sm text-red-500 mt-2'>At least one event is required</p>}
                                </div>

                                <div className="mb-8">
                                    <label htmlFor="input-url" className='block text-sm mb-2'>Url</label>
                                    <FormInput type="text" name="url" id="input-url" className="w-full" placeholder="Enter url" />
                                    {touched['url'] && errors['url'] && <p className='text-sm text-red-500 mt-2'>{errors['url'] as string}</p>}
                                </div>


                                <div className='flex justify-end items-center'>
                                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                        Cancel
                                    </Button>

                                    <Button type='submit' loading={saveMutation.isPending} className='' disabled={saveMutation.isPending}>
                                        {webhook ? "Save" : "Create Webhook"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}


const validationSchema = yup.object().shape({
    entity: yup.string().trim().required(),
    events: yup.array().min(1).required(),
    //url: yup.string().trim().required(),
    url: yup.string().trim().required("Url is required").matches(regexStrings.absoluteUrl, "Invalid Url provided")
});


interface Props {
    open: boolean;
    thirdParty: any;
    webhook: any;
    onClose: () => void;
    onAdded?: (data: any) => void;
    onUpdated?: (data: any) => void;
}

const entityList = [
    { label: 'Customer', value: 'CUSTOMER' },
    { label: 'Invoice', value: 'INVOICE' },
    { label: 'Sika Id', value: 'SIKA_ID' },
    { label: 'Transaction', value: 'TRANSACTION' },
];
// CUSTOMER, INVOICE, SIKA_ID, TRANSACTION - entities

const eventList = [
    { label: 'Create', value: 'CREATE' },
    { label: 'Update', value: 'UPDATE' },
    //{ label: 'Delete', value: 'DELETE' },
]

export default ThirdPartyWebhookFormModal;