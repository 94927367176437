import React from "react";
import DataState from "./DataState";



function ListState({ isLoading, isError, listCount, totalDataCount, hasSearch, className = '', onTryAgain }: Props) {
    return (
        <React.Fragment>
            {isLoading && <DataState className={className} icon="loader" caption="Loading Data" />}
            {isError && <DataState className={className} title="Error" caption="An error occurred while loading data." tryAgain={onTryAgain} />}

            {
                !isLoading && !isError && listCount === 0 &&
                <React.Fragment>
                    {
                        totalDataCount > 0 &&
                        <React.Fragment>
                            Wrong page
                        </React.Fragment>
                    }
                    {
                        totalDataCount === 0 &&
                        <React.Fragment>
                            {hasSearch && <DataState className={className} title="No Data Found" caption="No data found for the search scenarios." />}
                            {!hasSearch && <DataState className={className} caption="No data found" />}
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}


interface Props {
    className?: string;

    hasSearch: boolean;
    listCount: number;
    totalDataCount: number;

    isLoading: boolean;
    isError: boolean;

    onTryAgain?: () => void;
}


export default ListState;