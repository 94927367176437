import { unifiedApi } from '../httpService';



const schoolQueryService = {
    getSchools: async (query: object): Promise<any> => {
        try {
            const result = await unifiedApi.get(`/api/v1/schools`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    /* getModule: async (id: number | string): Promise<any> => {
        try {
            const result = await unifiedApi.get(`/api/v1/modules/${id}`, {});
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                return new Promise((_, reject) => reject(new Error('NOT_FOUND')));
            }
            return new Promise((_, reject) => reject(new Error("An unexpected Error occurred")));
        }
    },



    createModule: async (data: object): Promise<any> => {
        try {
            const result = await unifiedApi.post('/api/v1/modules', data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },



    updateModule: async (id: number, data: any): Promise<any> => {
        try {
            const result = await unifiedApi.put(`/api/v1/modules/${id}`, data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }
            return new Promise((_, reject) => reject('An unexpected Error'));
        }
    },



    deleteModule: async (id: number): Promise<any> => {
        try {
            const result = await unifiedApi.delete(`/api/v1/modules/${id}`);
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    }, */
};


export default schoolQueryService;