import React, { useState } from 'react';
import { Button } from '../../../../../../../components/common';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { FormError } from '../../../../../../../components/form';
import ModalAddModuleToPlan from '../../modals/ModalAddModuleToPlan';


function SubscriptionPlanModuleFeaturesForm({ isSubmitting, onStepChange }: Props) {
    const { values: { moduleAndFeatures }, errors, setTouched, submitForm } = useFormikContext<any>();
    const [selectModuleForm, setSelectModuleForm] = useState({ open: false, module: null, moduleIndex: 0 });

    const handleNext = async () => {
        setTouched({
            moduleAndFeatures: true,
        });

        if (!errors['moduleAndFeatures']) {
            submitForm();
        }
    };


    return (
        <React.Fragment>
            <div className='mb-10'>
                <FieldArray
                    name="moduleAndFeatures"
                    render={arrayHelpers => (
                        <React.Fragment>
                            <div className='flex justify-between flex-wrap gap-x-12 gap-y-2 mb-8'>
                                <h5 className='font-medium text-xl'>Modules & Features</h5>

                                <Button
                                    type='button'
                                    //onClick={() => arrayHelpers.push({ id: null })}
                                    onClick={() => setSelectModuleForm({ open: true, module: null, moduleIndex: 0 })}
                                    className='flex items-center gap-1.5 text-blue  bg-black/5 hocus:bg-black/10  dark:bg-white/5 dark:hocus:bg-white/10 px-4 py-2.5'
                                >
                                    <i className="ri-add-fill"></i>
                                    <span>Add Module</span>
                                </Button>
                            </div>

                            {
                                moduleAndFeatures?.length > 0 &&
                                <div className='grid grid-cols-2 gap-10'>
                                    {
                                        moduleAndFeatures.map((module: any, moduleIndex: number) =>
                                            <div key={module?.id} className='flex flex-col border dark:border-gray-700 rounded-2xl'>
                                                <div className='flex justify-between gap-x-6 border-b dark:border-gray-700 py-3 px-5 mb-3'>
                                                    <div>
                                                        <p className='text-lg font-medium'>{module?.name}</p>
                                                        <p className='text-gray-500 dark:text-gray-400 line-clamp-1'>{module?.description}</p>
                                                    </div>


                                                </div>

                                                <div className='flex-1 flex flex-col px-5 py-3'>
                                                    <p className='font-medium'>Features</p>

                                                    <div className='flex-1 divide-y dark:divide-gray-600 pl-4 mb-4'>
                                                        {
                                                            module?.features?.map((featureItem: any, featureItemIndex: number) => (
                                                                <div key={featureItem?.id} className='flex gap-x-2.5 py-3'>
                                                                    <span>{featureItemIndex + 1}.</span>
                                                                    <div>
                                                                        <p>{featureItem?.name}</p>
                                                                        <p className='text-sm text-gray-500 dark:text-gray-400 line-clamp-1'>{featureItem?.description}</p>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                    <div className='flex justify-between items-center flex-wrap gap-5 px-2 pb-2'>
                                                        <button type='button' onClick={() => setSelectModuleForm({ open: true, module: module, moduleIndex })} className='text-blue hocus:text-blue-600' title='Remove Item'>Change</button>
                                                        <button type='button' onClick={() => arrayHelpers.remove(moduleIndex)} className='text-red-500 hocus:text-red-600' title='Remove Item'>Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            }

                            {
                                moduleAndFeatures?.length === 0 &&
                                <div className='text-center py-4'>
                                    <p className='text-gray-500 dark:text-gray-400 mb-0.5'>Click on 'Add Module' above to add modules and features</p>
                                    <ErrorMessage name="moduleAndFeatures" component={FormError} />
                                </div>
                            }

                            <ModalAddModuleToPlan
                                open={selectModuleForm.open}
                                module={selectModuleForm.module}
                                onChange={(data) => {
                                    if (selectModuleForm.module) {
                                        arrayHelpers.replace(selectModuleForm.moduleIndex, data);
                                    } else {
                                        arrayHelpers.push(data);
                                    }
                                }}
                                onClose={() => setSelectModuleForm((prevState) => ({ ...prevState, open: false }))}
                                addedModules={moduleAndFeatures?.map((item: any) => item.id)}
                            />
                        </React.Fragment>
                    )}
                />
            </div>


            <div className='flex items-center gap-6'>
                <Button
                    type='button'
                    onClick={() => onStepChange(1)}
                    className='flex items-center gap-1.5 text-blue  bg-black/5 hocus:bg-black/10  dark:bg-white/5 dark:hocus:bg-white/10'
                >
                    Back
                </Button>

                <Button type='button' onClick={handleNext} loading={isSubmitting} className='' >
                    Save
                </Button>
            </div>
        </React.Fragment>
    );
}




/* 

 <div className="space-y-6 mb-10">
                {
                    [1, 2, 3, 4].map(item =>
                        <Disclosure key={item}>
                            {({ open }) => (
                                <div className='border dark:border-gray-700 rounded-3xl'>
                                    <Disclosure.Button as='div' className="flex justify-between items-start gap-8 cursor-pointer p-5">
                                        {({ open }) => (
                                            <>
                                                <div className='flex items-start gap-7'>
                                                    <div className='mt-1'>
                                                        <Switch
                                                            //checked={values["allowCashout"]}
                                                            //onChange={(on: boolean) => setFieldValue("allowCashout", on)}
                                                            className={`ui-not-checked:bg-gray-200 dark:ui-not-checked:bg-gray-700 ui-checked:bg-green-600 relative inline-flex items-center h-6 w-11 rounded-full`}
                                                        >
                                                            <span className={`translate-x-1 ui-checked:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                                        </Switch>
                                                    </div>

                                                    <div>
                                                        <p className='font-medium'>Child Security</p>
                                                        <p className='text-gray-500 dark:text-gray-400 line-clamp-1'>Some description...</p>
                                                        <p className='text-blue text-sm'>3 out of 3 selected</p>
                                                    </div>
                                                </div>

                                                <span className='text-gray-500 dark:text-gray-400 text-xl'>
                                                    {open ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>}
                                                </span>
                                            </>
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-5">
                                        <div className="ml-16 border-t dark:border-gray-700 space-y-4 py-6">
                                            {
                                                [1, 2, 3].map(item =>
                                                    <div key={`check-${item}`} className='flex gap-4 pl-2'>
                                                        <FormCheckBox id={`check-${item}`} />
                                                        <label htmlFor={`check-${item}`} className='cursor-pointer'>
                                                            <p className=''>Feature {item}</p>
                                                            <p className='text-sm text-gray-500 dark:text-gray-400'>Some description</p>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Disclosure.Panel>
                                </div>
                            )}
                        </Disclosure>
                    )
                }
            </div>



*/






interface Props {
    isSubmitting: boolean;
    onStepChange: (step: number) => void;
}


export default SubscriptionPlanModuleFeaturesForm;