import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ListLoader } from '../../../../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../../../../components/common';
import configurationService from '../../../../../../../../../services/api/payments/configurationService';
import useToast from '../../../../../../../../../state/hooks/useToast';
//import { Menu } from '@headlessui/react';
//import PopoverWithFloat from '../../../../../../../../../components/hoc/PopoverWithFloat';
import ConfigThresholdsSection from './components/ConfigThresholdsSection';
import ConfigRequirePaymentAuthorizationSection from './components/ConfigRequirePaymentAuthorizationSection';
import ConfigNegativeTransactionsSection from './components/ConfigNegativeTransactionsSection';
import ConfigMaxNegativeTransactionSection from './components/ConfigMaxNegativeTransactionSection';
import ConfigOfflineAccessSection from './components/ConfigOfflineAccessSection';


function SchoolPaymentConfigurationsPage() {
    const { profile, paymentClient }: any = useOutletContext();
    const { addToast } = useToast();

    const [pageStatus, setPageStatus] = useState({ loading: false, error: false, notFound: false });
    const [configuration, setConfiguration] = useState<any>(null);


    useEffect(() => {
        getConfiguration();
    }, [paymentClient]);


    const getConfiguration = async () => {
        if (paymentClient.client) {
            if (!configuration) {
                setPageStatus({ loading: true, error: false, notFound: false });
                const result = await configurationService.getConfiguration(paymentClient.client.id);
                if (result?.success) {
                    setConfiguration(result.data);
                    setPageStatus({ loading: false, error: false, notFound: false });
                } else {
                    if (result.notFound) {
                        setPageStatus({ loading: false, error: true, notFound: true });
                    } else {
                        setPageStatus({ loading: false, error: true, notFound: false });
                    }
                }
            }
        } else {
            setPageStatus({ loading: paymentClient.loading, error: paymentClient.error, notFound: paymentClient.notFound, })
        }
    }


    return (
        <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
            <div className='flex justify-between items-center flex-wrap gap-2'>
                <h2 className='font-medium text-xl'>Payments Configurations</h2>

                {/* <Menu as="div" className="relative inline-block text-left">
                    <PopoverWithFloat placement='bottom-end' portal>
                        <Menu.Button type='button' className={`cursor-pointer flex items-center gap-x-2 transition-colors duration-300 bg-gray-100 hocus:bg-gray-200  dark:bg-gray-700 dark:hocus:bg-gray-600 rounded-lg py-2.5 px-4`}>
                            <span>Actions</span>
                            <i className="ri-arrow-down-s-line opacity-70"></i>
                        </Menu.Button>

                        <Menu.Items as='div' unmount={false} className="w-72 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                            <Menu.Item as="button" type='button' onClick={() => setAllowNegativeTransactionForm(true)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Allow Negative Transactions
                            </Menu.Item>

                            <Menu.Item as="button" type='button' onClick={() => setMaxNegativeTransactionForm(true)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Set Max Negative Transactions Threshold
                            </Menu.Item>

                            <Menu.Item as="button" type='button' onClick={() => setOfflineTransactionAccess(true)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                Change Offline Access
                            </Menu.Item>
                        </Menu.Items>
                    </PopoverWithFloat>
                </Menu> */}
            </div>

            {
                !pageStatus.loading && !pageStatus.error && configuration &&
                <div className='divide-y dark:divide-gray-700'>
                    <ConfigThresholdsSection configuration={configuration} />
                    <ConfigRequirePaymentAuthorizationSection
                        configuration={configuration}
                        school={profile}
                        client={paymentClient.client}
                        setConfiguration={(config) => setConfiguration((prevState: any) => ({ ...prevState, ...config }))}
                    />
                    <ConfigNegativeTransactionsSection
                        configuration={configuration}
                        school={profile}
                        client={paymentClient.client}
                        setConfiguration={(config) => setConfiguration((prevState: any) => ({ ...prevState, ...config }))}
                    />
                    <ConfigMaxNegativeTransactionSection
                        configuration={configuration}
                        school={profile}
                        client={paymentClient.client}
                        setConfiguration={(config) => setConfiguration((prevState: any) => ({ ...prevState, ...config }))}
                    />
                    <ConfigOfflineAccessSection
                        configuration={configuration}
                        school={profile}
                        client={paymentClient.client}
                        setConfiguration={(config) => setConfiguration((prevState: any) => ({ ...prevState, ...config }))}
                    />
                </div>
            }

            {
                // if it's loading
                pageStatus.loading &&
                <ListLoader loadingText='Loading Configuration' className='px-8 py-24' />
            }

            {
                // if configuration is not found
                !pageStatus.loading && pageStatus.error && pageStatus.notFound &&
                <ErrorLoading title='Configuration Not Found' message='No configuration exist for this school' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                !pageStatus.loading && pageStatus.error && !pageStatus.notFound &&
                <ErrorLoading title='Error Loading Configuration' message='An unexpected error occurred while loading configuration' className='px-8 py-24' onTryAgain={getConfiguration} />
            }
        </div>
    );
}

export default SchoolPaymentConfigurationsPage;