import React from 'react';

function ConfigThresholdsSection({ configuration }: Props) {
    return (
        <div className='py-8'>
            <h3 className='text-lg font-medium mb-4'>Thresholds</h3>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                <div className='group'>
                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Transaction Limit</p>
                    <p>GHS {configuration?.dailyTransactionAmountThreshold.toFixed(2)}</p>
                </div>
                <div className=''>
                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Top Up Limit</p>
                    <p>GHS {configuration?.dailyTopUpAmountThreshold.toFixed(2)}</p>
                </div>
                <div className=''>
                    <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Daily Withdrawal Limit</p>
                    <p>GHS {configuration?.dailyCashWithdrawalThreshold.toFixed(2)}</p>
                </div>
            </div>
        </div>
    );
}

interface Props {
    configuration: any;
}

export default ConfigThresholdsSection;