import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Modal from '../../../../../../components/modals/Modal';
import { Button } from '../../../../../../components/common';
import subscriptionPlansService from '../../../../../../services/api/subscriptions/subscriptionPlansService';
import { Disclosure, Tab } from '@headlessui/react';
import { format } from 'date-fns';
import classNames from 'classnames';


function ModalPlanDetails({ open, plan, onClose }: Props) {

    const planQuery = useQuery({
        queryKey: ['subscriptions', 'plans', plan?.id],
        queryFn: ({ }) => subscriptionPlansService.getPlan(plan?.id),
        //initialData: { ...module },
        placeholderData: { ...plan },
        enabled: open,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    return (
        <Modal open={open} onClose={onClose}>
            <div className="inline-block w-[40rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Feature Details</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className=''>
                        <div className='flex items-end pb-4'>
                            <div className='flex-1'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Name</p>
                                <p>{planQuery.data?.name}</p>
                            </div>

                            <span className={`inline-block font-medium text-sm uppercase ${planQuery.data?.status === 'ACTIVE' ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>{planQuery.data?.status}</span>
                        </div>


                        <div>
                            <Tab.Group>
                                <Tab.List as='div' className="border-b dark:border-gray-700">
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Details</Tab>
                                    <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Modules & Features</Tab>
                                </Tab.List>
                                <Tab.Panels as='div' className="pb-8 px-4">
                                    <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-800">
                                        <div className='py-4'>
                                            <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Description</p>
                                            <p>{planQuery.data?.description}</p>
                                        </div>

                                        <div className='grid grid-cols-2 gap-x-6'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Target</p>
                                                <p>{planQuery.data?.target}</p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Frequency</p>
                                                <p>{planQuery.data?.defaultFrequency}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2 gap-x-6'>
                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Price</p>
                                                <p>{planQuery.data?.defaultPrice?.toFixed(2)}</p>
                                            </div>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Active Subscriptions</p>
                                                <p>{planQuery.data?.activeSubscriptionCount} / {planQuery.data?.subscriptionCount}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2 gap-x-6'>

                                            <div className='py-4'>
                                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Date Created</p>
                                                <p>{planQuery.data?.createdDate && format(new Date(planQuery.data?.createdDate), 'd LLL yyyy h:mm a')}</p>
                                            </div>

                                        </div>
                                    </Tab.Panel>

                                    <Tab.Panel unmount={false} as='div' className="pt-6">
                                        <div className='space-y-5'>
                                            {
                                                planQuery.data?.modules?.map((moduleItem: any, moduleIndex: number) => (
                                                    <Disclosure as="div" key={moduleItem?.id} defaultOpen={moduleIndex === 0} className="border dark:border-gray-700 rounded-2xl">
                                                        {({ open }) => (
                                                            <>
                                                                <Disclosure.Button as="div" className="flex justify-between gap-x-6 cursor-pointer py-4 px-5">
                                                                    <div>
                                                                        <p>{moduleItem?.name}</p>
                                                                        <p
                                                                            className={classNames(
                                                                                'text-sm text-gray-500 dark:text-gray-400', {
                                                                                'line-clamp-1': !open,
                                                                            })}
                                                                        >
                                                                            {moduleItem?.description}
                                                                        </p>
                                                                    </div>


                                                                    <span className='text-xl text-gray-500 dark:text-gray-400'>
                                                                        {open ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>}
                                                                    </span>
                                                                </Disclosure.Button>
                                                                <Disclosure.Panel className="px-5 pb-4">
                                                                    <div className='border-t dark:border-gray-700 pt-4'>
                                                                        <p className='font-semibold mb-1'>Features</p>
                                                                        <ul className='divide-y dark:divide-gray-800 pl-4'>
                                                                            {
                                                                                moduleItem?.features?.map((feature: any, featureIndex: number) => (
                                                                                    <li key={feature.id} className='flex gap-x-2 py-2'>
                                                                                        <span>{featureIndex + 1}.</span>
                                                                                        <div>
                                                                                            <p>{feature?.name}</p>
                                                                                            <p className='text-sm text-gray-500 dark:text-gray-400'>{feature.description}</p>
                                                                                        </div>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Disclosure.Panel>
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                ))
                                            }
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>

                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

interface Props {
    open: boolean;
    plan: any;
    onClose: () => void;
}

export default ModalPlanDetails;