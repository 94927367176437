import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FormError, FormInput, FormTextArea, RadioGroupInput, SelectInput } from '../../../../../../../components/form';
import { Button } from '../../../../../../../components/common';


function SubscriptionPlanDetailsForm({ onStepChange }: Props) {
    const { values: { details }, errors, setFieldValue, setTouched, setFieldTouched } = useFormikContext<any>();


    const handleNext = async () => {
        setTouched({
            details: {
                name: true,
                description: true,
                defaultPrice: true,
                defaultFrequency: true,
                target: true,
                status: true,
            }
        });

        if (!errors['details']) {
            onStepChange(2);
        }
    };


    return (
        <div>
            <h5 className='font-medium text-xl mb-8'>Subscription Plan Details</h5>

            <div className="mb-8">
                <label htmlFor="input-name" className='block mb-2'>Name</label>
                <FormInput type="text" name="details.name" id="input-name" className="w-full" placeholder="Enter name" />
                <ErrorMessage name="details.name" component={FormError} />
            </div>

            <div className="mb-8">
                <label htmlFor="input-description" className='block mb-2'>Description</label>
                <FormTextArea name='details.description' id="input-description" className='w-full' placeholder="Type here" rows={4} />
                <ErrorMessage name="details.description" component={FormError} />
            </div>

            <div className='mb-8'>
                <label htmlFor="" className='block mb-2'>Select type of subscription</label>
                <RadioGroupInput
                    value={details['target']}
                    options={[
                        { label: "Parent Subscription", value: 'PARENT' },
                        { label: "School Subscription", value: 'SCHOOL' },
                    ]}
                    onChange={(value) => setFieldValue('details.target', value)}
                />
                <ErrorMessage name="details.target" component={FormError} />
            </div>


            <div className='grid grid-cols-3 gap-6 mb-8'>
                <div className="">
                    <label htmlFor="input-price" className='block mb-2'>Price</label>
                    <FormInput type="number" name="details.defaultPrice" id="input-price" className="w-full" placeholder="0.00" />
                    <ErrorMessage name="details.defaultPrice" component={FormError} />
                </div>

                <div className="">
                    <label htmlFor="select-frequency" className='block mb-2'>Frequency</label>
                    <SelectInput
                        items={[
                            { label: 'Daily', value: 'DAILY' },
                            { label: 'Weekly', value: 'WEEKLY' },
                            { label: 'Monthly', value: 'MONTHLY' }
                        ]}
                        placeholder="Select Frequency"
                        value={details["defaultFrequency"]}
                        onChange={(selected) => setFieldValue("details.defaultFrequency", selected.value)}
                        onBlur={() => setFieldTouched('details.defaultFrequency', true)}
                    />
                    <ErrorMessage name="details.defaultFrequency" component={FormError} />
                </div>
            </div>


            <div className='mb-10'>
                <label htmlFor="" className='block mb-2'>Status</label>
                <RadioGroupInput
                    value={details['status']}
                    options={[
                        { label: "Active", value: 'ACTIVE' },
                        { label: "Inactive", value: 'INACTIVE' },
                    ]}
                    onChange={(value) => setFieldValue('details.status', value)}
                />
            </div>

            <div className='flex items-center gap-6'>
                <Button type='button' onClick={handleNext} className='' >
                    Next
                </Button>
            </div>
        </div>
    );
}


interface Props {
    isSubmitting: boolean;
    onStepChange: (step: number) => void;
}



export default SubscriptionPlanDetailsForm;