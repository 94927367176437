import { PropsWithChildren, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import Loader from "../svgs/Loader";


function DataState({ children, className, title, caption, icon, tryAgain }: Props) {
    return (
        <div className={twMerge('max-w-md text-center mx-auto px-8 py-24', className)}>
            {
                icon &&
                <div className="mb-4">
                    {
                        typeof icon === 'string' &&
                        <div className="">
                            <span className="inline-block text-gray-300 dark:text-gray-500" style={{ width: '3rem' }}>
                                {iconList[icon as keyof typeof iconList]}
                            </span>
                        </div>
                    }

                    {typeof icon !== 'string' && icon}
                </div>
            }

            {title && <p className='text-xl font-medium mb-1'>{title}</p>}
            {caption && <p className='text-muted mb-6'>{caption}</p>}

            {children}

            {
                tryAgain &&
                <div className="mt-1">
                    <button type='button' onClick={tryAgain} className='text-blue-500 hover:underline'>Try Again</button>
                </div>
            }
        </div>
    );
}


const iconList = {
    loader: <Loader />,
};


export interface Props extends PropsWithChildren {
    className?: string;
    icon?: keyof typeof iconList | ReactNode;
    iconSize?: number;
    title?: string;
    caption?: string;
    tryAgain?: () => void;
}


export default DataState;