import React, { useEffect, useState } from 'react';

function TagInput({ value, onChange }: Props) {
    const [typeInput, setTypeInput] = useState("");
    const [tags, setTags] = useState<string[]>([]);


    useEffect(() => {
        if (value) {
            setTags(value)
        }
    }, [value]);


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === ',' || e.key === ';' || e.key === 'Enter') {
            addTag();
            e.preventDefault();
        }

        if (e.key === 'Backspace' || e.key === 'Delete') {
            removeTag(tags.length - 1);
        }
    };


    const addTag = () => {
        const trimmedValue = typeInput.trim();
        if (trimmedValue && !tags.includes(trimmedValue)) {
            const tagSet = [...tags, trimmedValue];
            setTags([...tagSet]);
            if (onChange) {
                onChange(tagSet);
            }
        }
        setTypeInput('');
    };


    // Function to remove a tag
    const removeTag = (tagIndex: number) => {
        const tagSet = tags.filter((_, index) => index !== tagIndex);
        setTags([...tagSet]);
        if (onChange) {
            onChange(tagSet);
        }
    };


    return (
        <div className='flex items-center flex-wrap gap-x-3 gap-y-2 bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 focus:border-blue-500/60 dark:focus:border-blue-500/60 focus:outline-none rounded-lg py-2.5 px-4'>
            <div className="flex items-center flex-wrap gap-x-3 gap-y-2">
                {tags.map((tag, tagIndex) => (
                    <div key={tagIndex} className="flex items-center gap-x-2 bg-black/10 dark:bg-white/10 rounded-lg py-1 px-2">
                        <span className='text-sm'>
                            {tag}
                        </span>
                        <button
                            type='button'
                            onClick={() => removeTag(tagIndex)}
                            className='text-sm transition-opacity duration-200 opacity-50 hover:opacity-70'
                        >
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={typeInput}
                onChange={(e) => setTypeInput(e.target.value)}
                onBlur={() => addTag()}
                onKeyDown={handleKeyDown}
                placeholder="Type and press ',' ';' or Enter"
                className='bg-transparent flex-1'
            />
        </div>
    );
}

interface Props {
    value?: string[];
    onChange?: (tags: string[]) => void;
}

export default TagInput;