import { paymentsApi } from "../httpService";
import axios, { } from "axios";



const webhookService = {
    getWebhooks: async (clientKey: string, query: object): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/third-parties/webhooks`, {
                params: query,
                headers: {
                    'X-Client-Key': clientKey,
                    'Authorization': '',
                },
            });
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    getWebhook: async (id: number | string, query: object = {}): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/third-parties/webhooks/${id}`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                return new Promise((_, reject) => reject(new Error('NOT_FOUND')));
            }
            return new Promise((_, reject) => reject(new Error("An unexpected Error occurred")));
        }
    },



    createWebhook: async (clientKey: string, data: object): Promise<any> => {
        try {
            const result = await paymentsApi.post('/api/v1/third-parties/webhooks', data, {
                headers: {
                    'X-Client-Key': clientKey,
                    'Authorization': '',
                },
            });
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    updateStatus: async (id: number | string, clientKey: string, data: object): Promise<any> => {
        try {
            const result = await paymentsApi.patch(`/api/v1/third-parties/webhooks/${id}/status`, data, {
                headers: {
                    'X-Client-Key': clientKey,
                    'Authorization': '',
                },
            });
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },
};


export default webhookService;