import React, { useState } from 'react';
import ModalAllowNegativeTransactions from '../../../modals/ModalAllowNegativeTransactions';
import useToast from '../../../../../../../../../../state/hooks/useToast';

function ConfigNegativeTransactionsSection({ configuration, school, client, setConfiguration }: Props) {
    const { addToast } = useToast();
    const [allowNegativeTransactionForm, setAllowNegativeTransactionForm] = useState(false);


    return (
        <React.Fragment>
            <div className='py-8'>
                <div className='flex flex-wrap gap-x-3 gap-y-1 mb-3'>
                    <h3 className='text-lg font-medium'>Allow Negative Transactions</h3>
                    <button type='button' onClick={() => setAllowNegativeTransactionForm(true)} className='text-blue hover:underline'>Change</button>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                    <p className='flex items-center gap-x-2'>
                        Turned
                        {configuration.allowNegativeTransaction && <span className='inline-block text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                        {!configuration.allowNegativeTransaction && <span className='inline-block text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                        {(!configuration?.allowNegativeTransactionDirectoryIds || configuration?.allowNegativeTransactionDirectoryIds?.length === 0) && "For Every student"}
                        {configuration?.allowNegativeTransactionDirectoryIds?.length === 1 && "For 1 Campus"}
                        {configuration?.allowNegativeTransactionDirectoryIds?.length > 1 && `${configuration?.allowNegativeTransactionDirectoryIds.length} For Campuses`}
                    </p>
                </div>
            </div>

            <ModalAllowNegativeTransactions
                isOpen={allowNegativeTransactionForm}
                school={school}
                client={client}
                paymentConfig={configuration}
                onRequirementChanged={(config) => {
                    setConfiguration(config);
                    addToast({
                        text: "Allow negative transactions successfully updated",
                        type: "success"
                    });
                }}
                onClose={() => setAllowNegativeTransactionForm(false)}
            />
        </React.Fragment>
    );
}


interface Props {
    configuration: any;
    school: any;
    client: any;
    setConfiguration: (config: any) => void;
}

export default ConfigNegativeTransactionsSection;