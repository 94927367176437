import React, { LegacyRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Loader from '../svgs/Loader';


export const Button = React.forwardRef(({ loading = false, children, className, ...otherProps }: ButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    return (
        <button
            ref={ref}
            className={twMerge(`bg-blue-500 hover:bg-blue-600 disabled:bg-blue-400 disabled:cursor-not-allowed rounded-lg text-white pointer-events-auto py-3 px-5 ${loading ? 'opacity-70' : ''}`, className)}
            {...otherProps}
        >
            {!loading && children}
            {
                loading &&
                <span className='block w-6 h-6 mx-auto'>
                    <Loader />
                </span>
            }
        </button>
    );
})



/* export function Button({ loading = false, children, className, ...otherProps }: ButtonProps) {
    return (
        <button className={`transition-all duration-300 bg-blue-500 hover:bg-blue-600 disabled:bg-blue-400 rounded-lg text-white pointer-events-auto py-3 px-5 ${loading ? 'opacity-70' : ''} ${className}`} {...otherProps}>
            {!loading && children}
            {
                loading &&
                <span className='block w-6 h-6 mx-auto'>
                    <Loader />
                </span>
            }
        </button>
    );
} */



export function LinkButton({ children, className, ...otherProps }: LinkProps) {
    return (
        <Link className={`transition-all duration-300 bg-blue-500 hover:bg-blue-600 disabled:bg-blue-400 rounded-lg text-white pointer-events-auto py-3 px-5 ${className}`} {...otherProps}>
            {children}
        </Link>
    );
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
}

/* interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    loading?: boolean;
} */

export default Button;