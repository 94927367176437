import React from 'react';
import Modal from '../../../../../../components/modals/Modal';
import { format } from 'date-fns';
import { Button } from '../../../../../../components/common';


function ThirdPartyDetailsModal({ open, thirdParty, onClose }: Props) {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="inline-block w-[42rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">Third Party Details</h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 focus:outline-none">
                        <i className="ri-close-line"></i>
                    </button>
                </div>


                <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                    <div className='divide-y dark:divide-gray-700/80'>
                        <div className='flex-grow flex justify-between items-end gap-x-4 pb-6'>
                            <div>
                                <p className='text-gray-500 dark:text-gray-400 mb-1'>Company Name</p>
                                <h2 className='font-semibold text-lg'>{thirdParty?.companyName}</h2>
                            </div>

                            {
                                thirdParty?.status === "ACTIVE" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-2 px-3`}>{thirdParty?.status}</span>
                            }
                            {
                                thirdParty?.status !== "ACTIVE" && thirdParty?.status !== "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-2 px-3`}>{thirdParty?.status}</span>
                            }
                            {
                                thirdParty?.status === "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-2 px-3`}>{thirdParty?.status}</span>
                            }
                        </div>


                        <div className='grid sm:grid-cols-2 gap-8 py-6'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Start Time</p>
                                <p>{thirdParty?.contactPersonFirstName} {thirdParty?.contactPersonLastName}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Domain Name</p>
                                <p>{thirdParty?.domainName}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>E-Mail</p>
                                <p>{thirdParty?.email}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Primary Contact</p>
                                <p>{thirdParty?.primaryContact}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                                <p>{thirdParty?.country}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Timezone</p>
                                <p>{thirdParty?.timeZone}</p>
                            </div>
                            <div className='col-span-2'>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Secondary Contacts</p>
                                <p>{thirdParty?.secondaryContacts?.length > 0 ? thirdParty?.secondaryContacts?.join(", ") : 'N/A'}</p>
                            </div>
                        </div>


                        <div className='grid sm:grid-cols-2 gap-8 py-6'>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Data Source</p>
                                <p>{thirdParty?.contactPersonFirstName} {thirdParty?.dataSource}</p>
                            </div>
                            <div className=''>
                                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Used</p>
                                <p>{thirdParty?.lastUsed ? format(new Date(thirdParty?.lastUsed), 'd LLL yyyy h:mm a') : 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-end items-center'>
                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5'>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


interface Props {
    open: boolean;
    thirdParty: any;
    onClose: () => void;
}


export default ThirdPartyDetailsModal;