import { paymentsApi } from "../httpService";
import axios, { } from "axios";



const thirdPartyService = {
    getThirdParties: async (query: object): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/third-parties`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data));
        } catch (error: any) {
            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    getThirdParty: async (id: number | string, query: object = {}): Promise<any> => {
        try {
            const result = await paymentsApi.get(`/api/v1/third-parties/${id}`, {
                params: query,
            });
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                return new Promise((_, reject) => reject(new Error('NOT_FOUND')));
            }
            return new Promise((_, reject) => reject(new Error("An unexpected Error occurred")));
        }
    },



    createThirdParty: async (data: object): Promise<any> => {
        try {
            const result = await paymentsApi.post('/api/v1/third-parties', data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },


    updateThirdParty: async (id: number | string, data: object): Promise<any> => {
        try {
            const result = await paymentsApi.put(`/api/v1/third-parties/${id}`, data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }

            return new Promise((_, reject) => reject("An unexpected Error"));
        }
    },



    updateThirdPartyStatus: async (id: number | string, data: any): Promise<any> => {
        try {
            const result = await paymentsApi.patch(`/api/v1/third-parties/${id}/statuses`, data);
            return new Promise((resolve, _) => resolve(result.data.data));
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                //return new Promise((_, reject) => reject(error.response?.data?.message));
                return new Promise((_, reject) => reject(error.response?.data?.errors[0].message));
            }
            return new Promise((_, reject) => reject('An unexpected Error'));
        }
    },




};


export default thirdPartyService;