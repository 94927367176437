import React, { useState } from 'react';
import ModalOfflineAccessConfiguration from '../../../modals/ModalOfflineAccessConfiguration';
import useToast from '../../../../../../../../../../state/hooks/useToast';


function ConfigOfflineAccessSection({ configuration, school, client, setConfiguration }: Props) {
    const { addToast } = useToast();
    const [offlineTransactionAccess, setOfflineTransactionAccess] = useState(false);


    return (
        <React.Fragment>
            <div className='py-8'>
                <div className='flex flex-wrap gap-x-3 gap-y-1 mb-3'>
                    <h3 className='text-lg font-medium'>Offline Payments Access</h3>
                    <button type='button' onClick={() => setOfflineTransactionAccess(true)} className='text-blue hover:underline'>Change</button>
                </div>
                <div className=''>
                    <p className='flex items-center gap-x-2 mb-2'>
                        Turned
                        {configuration.allowOfflinePaymentByEmployees && <span className='inline-block text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                        {!configuration.allowOfflinePaymentByEmployees && <span className='inline-block text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                        for all employees
                    </p>
                    {
                        !configuration.allowOfflinePaymentByEmployees &&
                        configuration.allowOfflinePaymentForSpecificEmployeeEmails?.length > 0 &&
                        <p className='text-gray-500 dark:text-gray-400'>
                            <span className='font-medium'>Allowed for:</span> {configuration.allowOfflinePaymentForSpecificEmployeeEmails.join(', ')}
                        </p>
                    }
                </div>
            </div>


            <ModalOfflineAccessConfiguration
                isOpen={offlineTransactionAccess}
                school={school}
                client={client}
                paymentConfig={configuration}
                onConfigChanged={(config) => {
                    setConfiguration(config);
                    addToast({
                        text: "Offline payment access configuration updated",
                        type: "success"
                    });
                }}
                onClose={() => setOfflineTransactionAccess(false)}
            />
        </React.Fragment>
    );
}

interface Props {
    configuration: any;
    school: any;
    client: any;
    setConfiguration: (config: any) => void;
}

export default ConfigOfflineAccessSection;