import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import { Button } from '../../../../../../components/common';
import thirdPartyService from '../../../../../../services/api/payments/thirdPartyService';
import { FormInput, PhoneNumberInput, SelectInput } from '../../../../../../components/form';
import countryList from '../../../../../../config/countries-locale/country-list.json';
import useToast from '../../../../../../state/hooks/useToast';
import { useMutation, useQueryClient } from '@tanstack/react-query';


function ThirdPartyForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { thirdPartyId } = useParams();
    const { addToast } = useToast();


    const queryClient = useQueryClient();
    const queryKey = ['payments', 'third-paries', thirdPartyId];


    useEffect(() => {
        dispatch(changePageInfo({ title: thirdPartyId ? "Edit Third Party" : "Create Third Party" }));
    }, []);


    const saveMutation = useMutation({
        mutationFn: (data: any) => {
            return thirdPartyId ? thirdPartyService.updateThirdParty(thirdPartyId as string, data) : thirdPartyService.createThirdParty(data);
        }
    });



    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload = {
            ...formData,
            //secondaryContacts: null,
        };

        saveMutation.mutate(payload, {
            onSuccess: (data) => {
                addToast({
                    text: thirdPartyId ? "Third party updated successfully" : "Third party added successfully",
                    type: 'success'
                });

                setTimeout(() => {
                    if (thirdPartyId) {
                        queryClient.invalidateQueries({
                            queryKey,
                            refetchType: 'none',
                        });
                    }
                    queryClient.invalidateQueries({
                        queryKey: ['payments', 'third-paries'],
                    });
                }, 50);

                navigate('/payments/third-parties');
            },
            onError: () => {
            }
        });
    };


    return (
        <React.Fragment>
            {

                <div className='max-w-4xl bg-white dark:bg-gray-800 shadow dark:shadow-none divide-y dark:divide-gray-700 rounded-xl py-8 px-8'>
                    <Formik
                        initialValues={{
                            companyName: '',
                            contactPersonFirstName: '',
                            contactPersonLastName: '',
                            email: '',
                            domainName: '',
                            country: 'GH',
                            timeZone: '',
                            dataSource: '',
                            primaryContact: '',
                            secondaryContacts: [],
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    /* validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                    validateOnMount
                    enableReinitialize */
                    >
                        {({ values, errors, touched, setErrors, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid, isInitialValid }) => (
                            <Form className="">
                                {
                                    saveMutation.error &&
                                    <div className='inline-block bg-red-500/5 rounded-lg px-4 py-2 mb-4'>
                                        <p className='text-red-500' >{saveMutation.error as unknown as string}</p>
                                    </div>
                                }


                                <div className="mb-7">
                                    <label htmlFor="input-company-name" className='block mb-2'>Third Party Company Name</label>
                                    <FormInput type="text" name="companyName" id="input-company-name" className="w-full" placeholder="Enter company name" />
                                    {touched['companyName'] && errors['companyName'] && <p className='text-sm text-red-500 mt-2'>Name is Required</p>}
                                    {/* <ConsoleLog>
                                        {errors}
                                    </ConsoleLog> */}
                                </div>


                                <div className='grid sm:grid-cols-2 gap-7 mb-7'>
                                    <div className="">
                                        <label htmlFor="input-contact-person-first" className='block mb-2'>Contact Person First Name</label>
                                        <FormInput type="text" name="contactPersonFirstName" id="input-contact-person-first" className="w-full" placeholder="Enter first name" />
                                        {touched['contactPersonFirstName'] && errors['contactPersonFirstName'] && <p className='text-sm text-red-500 mt-2'>First name is Required</p>}
                                    </div>

                                    <div className="">
                                        <label htmlFor="input-contact-person-last" className='block mb-2'>Contact Person Last Name</label>
                                        <FormInput type="text" name="contactPersonLastName" id="input-contact-person-last" className="w-full" placeholder="Enter last name" />
                                        {touched['contactPersonLastName'] && errors['contactPersonLastName'] && <p className='text-sm text-red-500 mt-2'>Last name is Required</p>}
                                    </div>
                                </div>


                                <div className='grid sm:grid-cols-2 gap-7 mb-7'>
                                    <div className="7">
                                        <label htmlFor="select-country" className='block mb-2'>Country</label>
                                        <SelectInput
                                            items={countryList}
                                            placeholder="Select Country"
                                            value={values["country"]}
                                            onChange={(value) => setFieldValue("country", value)}
                                            onBlur={() => setFieldTouched('country', true)}
                                            labelKey='country'
                                            valueKey='iso'
                                            searchable
                                        />
                                        {touched['country'] && errors['country'] && <p className='text-sm text-red-500 mt-2'>Select country</p>}
                                    </div>

                                    <div className="">
                                        <label htmlFor="modal-select-timezone" className='block text-sm mb-2'>Timezone</label>
                                        <SelectInput
                                            items={["Africa/Accra", "Africa/Casablanca"]}
                                            placeholder="Select timezone"
                                            value={values["timeZone"]}
                                            onChange={(value: { label: string, value: string }) => setFieldValue("timeZone", value)}
                                            onBlur={() => setFieldTouched('timeZone', true)}
                                        />
                                        {touched['timeZone'] && errors['timeZone'] && <p className='text-sm text-red-500 mt-2'>Select timezone</p>}
                                    </div>
                                </div>


                                <div className='grid sm:grid-cols-2 gap-7 mb-7'>
                                    <div className="">
                                        <label htmlFor="select-source" className='block text-sm mb-2'>Data Source</label>
                                        <SelectInput
                                            items={[
                                                { label: "From MineX 360", value: "MINEX" },
                                                { label: "Fidelity", value: "FIDELITY" },
                                                { label: "From Direct Warrantee", value: "DW" },
                                            ]}
                                            placeholder="Select school source"
                                            value={values["dataSource"]}
                                            onChange={(selected: any) => setFieldValue("dataSource", selected.value)}
                                            onBlur={() => setFieldTouched('dataSource', true)}
                                        />
                                        {touched['dataSource'] && errors['dataSource'] && <p className='text-sm text-red-500 mt-2'>Select source</p>}
                                    </div>

                                    <div className="">
                                        <label htmlFor="input-domain-name" className='block mb-2'>Domain Name</label>
                                        <FormInput type="text" name="domainName" id="input-domain-name" className="w-full" placeholder="Enter domain name" />
                                        {touched['domainName'] && errors['domainName'] && <p className='text-sm text-red-500 mt-2'>Domain name is Required</p>}
                                    </div>
                                </div>


                                <div className='grid sm:grid-cols-2 gap-7 mb-7'>
                                    <div className="">
                                        <label htmlFor="input-email" className='block mb-2'>E-Mail</label>
                                        <FormInput type="text" name="email" id="input-email" className="w-full" placeholder="Enter email" />
                                        {touched['email'] && errors['email'] && <p className='text-sm text-red-500 mt-2'>Email is Required</p>}
                                    </div>

                                    <div className="">
                                        <label htmlFor="input-contact-number" className='block text-sm mb-2'>Contact Number</label>
                                        <PhoneNumberInput
                                            id="input-contact-number"
                                            name="primaryContact"
                                            className="w-full"
                                            //placeholder="Enter primary contact number"
                                            value={values["primaryContact"]}
                                            onChange={value => setFieldValue('primaryContact', value)}
                                            onBlur={() => setFieldTouched('primaryContact', true)}
                                        />
                                        {touched['primaryContact'] && errors['primaryContact'] && <p className='text-sm text-red-500 mt-2'>{errors['primaryContact'] as string}</p>}
                                    </div>
                                </div>

                                <div className='bg-gray-50 dark:bg-white/5 rounded-xl py-5 px-6 mb-10'>
                                    <label htmlFor="" className='block text-sm mb-4'>Add Secondary Contacts</label>

                                    <FieldArray
                                        name="secondaryContacts"
                                        render={arrayHelpers => (
                                            <React.Fragment>
                                                {
                                                    values.secondaryContacts.map((contactItem: string, contactItemIndex: number) =>
                                                        <div key={contactItemIndex} className='mb-4'>
                                                            <div className='flex items-center gap-x-3'>
                                                                <PhoneNumberInput
                                                                    id="input-contact-number"
                                                                    name={`secondaryContacts[${contactItemIndex}]`}
                                                                    className="w-full max-w-xs"
                                                                    //placeholder="Enter primary contact number"
                                                                    value={`secondaryContacts[${contactItemIndex}]`}
                                                                    onChange={value => setFieldValue(`secondaryContacts[${contactItemIndex}]`, value)}
                                                                //onBlur={() => setFieldTouched('primaryContact', true)}
                                                                />

                                                                <button type='button' onClick={() => arrayHelpers.remove(contactItemIndex)} className='w-9 h-9 flex justify-center items-center hover:bg-gray-50 dark:hover:bg-white/5 rounded-full'>
                                                                    <i className="ri-delete-bin-line"></i>
                                                                </button>
                                                            </div>

                                                            <ErrorMessage
                                                                name={`secondaryContacts[${contactItemIndex}]`}
                                                                render={(message) => <p className='text-sm text-red-500 mt-2'>{message}</p>}
                                                            />
                                                        </div>
                                                    )
                                                }

                                                <div>
                                                    <button type='submit' onClick={() => arrayHelpers.push('')} className='text-blue-500 hover:underline'>Add Contact</button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    />
                                </div>

                                <div className=''>
                                    <Button type='submit' loading={saveMutation.isPending} className='' disabled={saveMutation.isPending}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        </React.Fragment>
    );
}




const validationSchema = Yup.object().shape({
    companyName: Yup.string().trim().required('Required'),
    contactPersonFirstName: Yup.string().trim().required('Required'),
    contactPersonLastName: Yup.string().trim().required('Required'),
    email: Yup.string().email().trim().required('Required'),
    domainName: Yup.string().trim().required('Required'),
    country: Yup.string().trim().required('Required'),
    timeZone: Yup.string().trim().required('Required'),
    dataSource: Yup.string().trim().required('Required'),
    primaryContact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
    secondaryContacts: Yup.array().of(Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label("")),
});


//companyName: Yup.string().trim().required(''),
// contactPersonFirstName: Yup.string().trim().required(''),
/*     contactPersonLastName: Yup.string().trim().required(''),
    
email: Yup.string().email().trim().required(''),
    domainName: Yup.string().trim().required(''),
    country: Yup.string().trim().required(''),
    timeZone: Yup.string().trim().required(''),
    dataSource: Yup.string().trim().required(''),
    primaryContact: Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label(""),
    secondaryContacts: Yup.array().of(Yup.string().trim().required("Contact number is Required").test("invalid-number", "Invalid number provided", value => value ? isValidPhoneNumber(value) : true).label("")), */

export default ThirdPartyForm;