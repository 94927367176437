import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from '../../../../../../../../components/modals/Modal';
import { FormInput, MultiSelectInput, RadioGroupInput } from '../../../../../../../../components/form';
import { Button, ErrorLoading } from '../../../../../../../../components/common';
import { ListLoader } from '../../../../../../../../components/loaders';
import directoriesService from '../../../../../../../../services/api/payments/directoriesService';
import configurationService from '../../../../../../../../services/api/payments/configurationService';
import useAlert from '../../../../../../../../state/hooks/useAlert';


function ModalMaximumNegativeTransactionThresholdForm({ isOpen, client, school, paymentConfig, onClose, onRequirementChanged }: Props) {
    const { directoryList, setDirectoryList }: any = useOutletContext();

    const [submitStatus, setSubmitStatus] = useState({ loading: false, error: '' });
    const [optionsLoadingStatus, setOptionsLoadingStatus] = useState({ loading: false, error: false });


    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        if (isOpen && directoryList.length === 0) {
            getOptions();
        }
    }, [isOpen]);



    const getOptions = async () => {
        setOptionsLoadingStatus({ loading: true, error: false });
        const directoriesResult = await directoriesService.getDirectories({ clientId: client.id });

        if (directoriesResult.success) {
            setDirectoryList(directoriesResult.data);
            setOptionsLoadingStatus({ loading: false, error: false });
        } else {
            setOptionsLoadingStatus({ loading: false, error: true });
        }
    };





    const handleFormSubmit = async (formData: object, { resetForm }: any) => {
        const payload: any = { ...formData, clientId: client.id };

        if (payload.campuses === 'all') {
            payload.maximumNegativeTransactionThresholdDirectoryIds = [];
        } else {
            payload.maximumNegativeTransactionThresholdDirectoryIds = payload.maximumNegativeTransactionThresholdDirectoryIds.map((item: any) => item.id);
        }
        delete payload.campuses;

        openAlertConfirm({
            title: 'Confirm Changes',
            message: `Are you sure you want to make changes to Maximum Negative Transaction Threshold for ${client.name}?`,
            onConfirmed: () => handleSave(payload, resetForm),
            positiveButtonText: "Save Changes",
            negativeButtonText: 'Cancel',
        });
    }

    const handleSave = async (payload: any, resetForm: () => void) => {
        setSubmitStatus({ loading: true, error: '' });

        const result = await configurationService.updateMaxNegativeTransactionsThreshold(payload);
        if (result.success) {
            if (onRequirementChanged) {
                onRequirementChanged({ ...result.data });
            }
            //resetForm();
            onClose();
            setSubmitStatus({ loading: false, error: '' });
        } else {
            setSubmitStatus({ loading: false, error: result.message });
        }
    }


    const getSelectedDirectories = (directoryIds: string): any[] => directoryList.filter((item: any) => directoryIds?.includes(item.id));

    return (
        <Modal open={isOpen} onClose={submitStatus.loading ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-screen max-w-xl">
                <div className="flex justify-between mb-6 px-10 pt-8">
                    <div className='mr-5'>
                        <h2 className="font-medium text-xl">
                            Set Maximum Negative Transaction Threshold
                        </h2>
                    </div>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" disabled={submitStatus.loading}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>

                {
                    !optionsLoadingStatus.loading && !optionsLoadingStatus.error &&
                    <div className='max-h-[80vh] overflow-y-auto px-10 pb-6'>
                        <Formik
                            initialValues={{
                                amount: paymentConfig.maximumNegativeTransactionThreshold,
                                maximumNegativeTransactionThresholdDirectoryIds: getSelectedDirectories(paymentConfig.maximumNegativeTransactionThresholdDirectoryIds),
                                maximumNegativeTransactionThresholdGroupIds: [],
                                maximumNegativeTransactionThresholdCustomerIds: [],

                                campuses: paymentConfig.maximumNegativeTransactionThresholdDirectoryIds?.length === 0 ? 'all' : 'specific',
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, errors, touched, setErrors, setFieldValue, setFieldTouched, submitCount, isValid }) => (
                                <Form className="">
                                    {
                                        submitStatus.error && !submitStatus.loading &&
                                        <p className='text-red-500 mb-4' >{submitStatus.error}</p>
                                    }


                                    <div className="mb-6">
                                        <label htmlFor="modal-input-other-names" className='block mb-2'>Maximum Amount</label>
                                        <FormInput type="number" name="amount" id="modal-input-other-names" className="w-full" placeholder="0" min={0} />
                                        {touched['amount'] && errors['amount'] && <p className='text-sm text-red-500 mt-2'>Amount is required</p>}
                                    </div>


                                    <div className='mb-8'>
                                        <label htmlFor="modal-radio-apply-to" className='block mb-2'>All this max negative threshold to</label>
                                        <RadioGroupInput
                                            value={values['campuses']}
                                            options={[
                                                { label: "All students", value: 'all' },
                                                { label: "Specific campuses", value: 'specific' },
                                            ]}
                                            onChange={(value) => setFieldValue('campuses', value)}
                                        />
                                    </div>

                                    {
                                        values['campuses'] === 'specific' &&
                                        <div className='pb-5 -mt-2'>
                                            <label htmlFor="modal-select-groups" className='block mb-2'>Select campuses</label>
                                            <div className="">
                                                <MultiSelectInput
                                                    items={directoryList}
                                                    valueKey='id'
                                                    labelKey='name'
                                                    placeholder="Select Campuses"
                                                    value={values["maximumNegativeTransactionThresholdDirectoryIds"]}
                                                    //itemsShowLimit={6}
                                                    selectedLabel='Campus'
                                                    selectedLabelPlural='Campuses'
                                                    showSelectedItems={false}
                                                    onChange={(value) => setFieldValue("maximumNegativeTransactionThresholdDirectoryIds", value)}
                                                    onBlur={() => setFieldTouched('maximumNegativeTransactionThresholdDirectoryIds', true)}
                                                />
                                                {/*   {touched['groupIds'] && errors['groupIds'] && <p className='text-sm text-red-500 mt-2'>Select at least a group to receive feed</p>} */}
                                            </div>
                                        </div>
                                    }


                                    <div className='flex justify-end items-center mt-12'>
                                        <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                                            Cancel
                                        </Button>

                                        <Button type='submit' loading={submitStatus.loading} className='' disabled={submitStatus.loading}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                }

                {
                    (optionsLoadingStatus.loading || optionsLoadingStatus.error) &&
                    <div className='pb-10'>
                        {
                            optionsLoadingStatus.loading &&
                            <ListLoader loadingText='Loading Options' className='py-10' loaderSize={2} />
                        }
                        {
                            optionsLoadingStatus.error &&
                            <ErrorLoading title='Error Loading Options' message='An unexpected error occurred while loading options' className='px-8 py-16' onTryAgain={getOptions} />
                        }
                    </div>
                }
            </div>
        </Modal>
    );
}


interface Props {
    isOpen: boolean;
    client: any;
    school: any;
    paymentConfig: any;
    onClose: () => void;
    onRequirementChanged?: (paymentConfig: any) => void;
}


const validationSchema = Yup.object().shape({
    amount: Yup.string().trim().required().label(""),
});


export default ModalMaximumNegativeTransactionThresholdForm;