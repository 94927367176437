import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Menu, Tab } from '@headlessui/react';

import useToast from '../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import thirdPartyService from '../../../../../../services/api/payments/thirdPartyService';
import { ListLoader } from '../../../../../../components/loaders';
import { ErrorLoading } from '../../../../../../components/common';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import { format } from 'date-fns';
import useAlert from '../../../../../../state/hooks/useAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ThirdPartyWebhooksManagementTab from '../components/ThirdPartyWebhooksManagementTab';



function ThirdPartyDetailsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { thirdPartyId } = useParams();
    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();


    useEffect(() => {
        dispatch(changePageInfo({ title: "Third Party Details" }));
    }, []);


    const queryClient = useQueryClient();
    const queryKey = ['payments', 'third', 'paries', Number(thirdPartyId)];
    const thirdPartyQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => thirdPartyService.getThirdParty(thirdPartyId as string, { includeKeys: true }),
        placeholderData: { ...location?.state?.thirdParty },
        enabled: typeof thirdPartyId === 'string',
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const statusMutation = useMutation({
        mutationFn: (data: any) => thirdPartyService.updateThirdPartyStatus(thirdPartyId as string, data),
        onSuccess: (data) => {
            addToast({
                text: `Third party ${data?.status} successfully`,
                type: 'success',
            });

            queryClient.invalidateQueries({
                queryKey: ['payments', 'third-paries'],
            });


            queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                if (oldData) {
                    //const index = oldData.data.findIndex((item: any) => item.id === data.id);
                    //const newData = JSON.parse(JSON.stringify(oldData));
                    //newData.data[index] = data;
                    return { ...oldData, ...data };
                }
            });
        },
        onError: (_, data) => {
            addToast({
                text: `Sorry, could not ${data?.status} third party`,
                type: 'error',
            });
        }
    });


    const confirmStatusAction = (status: "ACTIVE" | "SUSPENDED") => {
        openAlertConfirm({
            title: `${status === "ACTIVE" ? 'Activate' : 'Suspend'} Third Party`,
            message: `Are you sure you want to ${status === "ACTIVE" ? 'activate' : 'suspend'} ${thirdPartyQuery?.data?.companyName}?`,
            positiveButtonText: status === "ACTIVE" ? 'Activate' : 'Suspend',
            onConfirmed: () => statusMutation.mutate({ status }),
        });
    }


    return (
        <React.Fragment>
            {
                !thirdPartyQuery.isFetching && !thirdPartyQuery.isError &&
                <div className='bg-white dark:bg-gray-800 shadow dark:shadow-none rounded-xl py-8 px-8'>
                    <div className='flex pb-5'>
                        <div className='flex-grow flex justify-between items-start'>
                            <div>
                                <p className='text-gray-500 dark:text-gray-400 mb-1'>Company Name</p>
                                <h2 className='font-semibold text-lg'>{thirdPartyQuery?.data?.companyName}</h2>
                            </div>

                            {
                                thirdPartyQuery?.data?.status === "ACTIVE" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-2 px-3 mr-3`}>{thirdPartyQuery?.data?.status}</span>
                            }
                            {
                                thirdPartyQuery?.data?.status !== "ACTIVE" && thirdPartyQuery?.data?.status !== "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-2 px-3 mr-3`}>{thirdPartyQuery?.data?.status}</span>
                            }
                            {
                                thirdPartyQuery?.data?.status === "SUSPENDED" &&
                                <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-2 px-3 mr-3`}>{thirdPartyQuery?.data?.status}</span>
                            }
                        </div>


                        <Menu as="div" className="relative inline-block text-left">
                            {
                                ({ open }) => (
                                    <PopoverWithFloat placement='bottom-end' portal>
                                        <Menu.Button type='button' className={`w-5 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ${open ? 'bg-black/5 dark:bg-white/5' : ''}`}>
                                            <i className="ri-more-2-fill"></i>
                                        </Menu.Button>

                                        <Menu.Items as='div' unmount={false} className="w-56 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                            {
                                                thirdPartyQuery?.data?.status === "ACTIVE" &&
                                                <Menu.Item
                                                    as="button"
                                                    type='button'
                                                    onClick={() => confirmStatusAction("SUSPENDED")}
                                                    className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                >
                                                    Suspend
                                                </Menu.Item>
                                            }

                                            {
                                                thirdPartyQuery?.data?.status !== "ACTIVE" &&
                                                <Menu.Item
                                                    as="button"
                                                    type='button'
                                                    onClick={() => confirmStatusAction("ACTIVE")}
                                                    className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                                >
                                                    Activate
                                                </Menu.Item>
                                            }
                                        </Menu.Items>
                                    </PopoverWithFloat>
                                )
                            }
                        </Menu>
                    </div>


                    <Tab.Group>
                        <Tab.List as='div' className="border-b dark:border-gray-700">
                            <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Details</Tab>
                            <Tab as='button' type='button' className="transition-colors duration-300 text-gray-500 dark:text-gray-400 ui-selected:text-blue-500 hocus:text-blue-500 dark:hocus:text-blue-500 dark:ui-selected:text-blue-500 border-b border-transparent ui-selected:border-blue-500 -mb-px py-3 px-6">Webhooks</Tab>
                        </Tab.List>
                        <Tab.Panels as='div' className="pb-8 px-4">
                            <Tab.Panel unmount={false} as='div' className="divide-y dark:divide-gray-700">
                                <div className='grid sm:grid-cols-2 gap-8 py-8'>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Drop Off Start Time</p>
                                        <p>{thirdPartyQuery?.data?.contactPersonFirstName} {thirdPartyQuery?.data?.contactPersonLastName}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Domain Name</p>
                                        <p>{thirdPartyQuery?.data?.domainName}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>E-Mail</p>
                                        <p>{thirdPartyQuery?.data?.email}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Primary Contact</p>
                                        <p>{thirdPartyQuery?.data?.primaryContact}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Country</p>
                                        <p>{thirdPartyQuery?.data?.country}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Timezone</p>
                                        <p>{thirdPartyQuery?.data?.timeZone}</p>
                                    </div>
                                    <div className='col-span-2'>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Secondary Contacts</p>
                                        <p>{thirdPartyQuery?.data?.secondaryContacts?.length > 0 ? thirdPartyQuery?.data?.secondaryContacts?.join(", ") : 'N/A'}</p>
                                    </div>
                                </div>


                                <div className='grid sm:grid-cols-2 gap-8 py-8'>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Data Source</p>
                                        <p>{thirdPartyQuery?.data?.contactPersonFirstName} {thirdPartyQuery?.data?.dataSource}</p>
                                    </div>
                                    <div className=''>
                                        <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Last Used</p>
                                        <p>{thirdPartyQuery?.data?.lastUsed ? format(new Date(thirdPartyQuery?.data?.lastUsed), 'd LLL yyyy h:mm a') : 'N/A'}</p>
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel unmount={false} as='div' className="pt-6">
                                {({ selected }) => (
                                    <ThirdPartyWebhooksManagementTab
                                        selected={selected}
                                        thirdParty={thirdPartyQuery?.data}
                                    />
                                )}
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            }


            {
                // if it's loading
                thirdPartyQuery.isFetching &&
                <ListLoader loadingText='Loading Third Party' className='px-8 py-24' />
            }

            {
                // if is not found
                thirdPartyQuery.isFetched && thirdPartyQuery.isError && thirdPartyQuery.error.message === 'NOT_FOUND' &&
                <ErrorLoading title='Third Party Not Found' message='Third party may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                thirdPartyQuery.isFetched && thirdPartyQuery.isError && thirdPartyQuery.error.message !== 'NOT_FOUND' &&
                <ErrorLoading title='Error Loading Third Party' message='An unexpected error occurred while loading third party' className='px-8 py-24' onTryAgain={thirdPartyQuery.refetch} />
            }
        </React.Fragment>
    );
}

export default ThirdPartyDetailsPage;