import { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../../components/modals/Modal';
import { Button } from '../../../../../../components/common';
import subscriptionPlansService from '../../../../../../services/api/subscriptions/subscriptionPlansService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import classNames from 'classnames';
import schoolQueryService from '../../../../../../services/api/unified-api/schoolQueryService';
import { SelectInput } from '../../../../../../components/form';
import clientQueryService from '../../../../../../services/api/payments/clientQueryService';


function ModalAttachSchoolToPlan({ open, plan, action, onClose, onAttached, onDetached }: Props) {
    const [selectedSchool, setSelectedSchool] = useState<any>();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectSchoolError, setSelectSchoolError] = useState(false);

    const debouncedSearchTerm = useMemo(() => debounce((value) => setSearchQuery(value), 500), []);
    const queryClient = useQueryClient();

    const schoolsQueryKey = ['search', 'schools', { name: searchQuery }];

    useEffect(() => {
        if (open) {
            saveMutation.reset();
            queryClient.invalidateQueries({ queryKey: schoolsQueryKey });
            setSearchQuery('');
            setSelectSchoolError(false);
            setSelectedSchool(undefined);
        }
    }, [open]);


    const schoolsQuery = useQuery({
        queryKey: schoolsQueryKey,
        queryFn: () => schoolQueryService.getSchools({ name: searchQuery }),
        enabled: !!searchQuery,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });

    const clientQuery = useQuery({
        queryKey: ['client', selectedSchool],
        queryFn: ({ }) => clientQueryService.getClientByReference(selectedSchool),
        //enabled: open && !!selectedSchool,
        enabled: false,
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    const saveMutation = useMutation({
        mutationFn: (data: any) => {
            return subscriptionPlansService.attachDetachSchools(data);
        }
    });


    useEffect(() => {
        if (open && clientQuery.data && selectedSchool) {
            handleSave();
        }
    }, [clientQuery.data]);


    const handleSubmit = () => {
        if (selectedSchool) {
            clientQuery.refetch();
        } else {
            setSelectSchoolError(true);
        }
    }


    const handleSave = () => {
        const payload = {
            planId: plan.id,
            clientIds: [clientQuery.data?.id],
            actionType: action,
        };

        saveMutation.mutate(payload, {
            onSuccess: (data) => {
                onClose();

                if (onAttached) {
                    onAttached(clientQuery.data);
                }

                if (onDetached) {
                    onDetached(clientQuery.data);
                }
            },
            onError: () => {
            }
        });
    }


    return (
        <Modal open={open} onClose={false ? () => null : onClose} closeOnOutsideClicked={false}>
            <div className="inline-block w-[36rem] max-w-full">
                <div className="flex justify-between mb-5 px-10 pt-8">
                    <h2 className="font-medium text-xl">
                        {action === 'ATTACH' ? 'Attach' : 'Detach'} School
                    </h2>

                    <button type="button" onClick={onClose} className="text-2xl text-gray-500 [&:not(:disabled)]:hover:text-gray-600 dark:text-gray-600 dark:[&:not(:disabled)]:hover:text-gray-500 focus:outline-none" >
                        <i className="ri-close-line"></i>
                    </button>
                </div>
            </div>


            <div className='max-h-[80vh] max-w-[36rem] overflow-y-auto px-10 pb-6'>
                <div className='mb-8'>
                    <p className='mb-1'>{action === 'ATTACH' ? 'Attach' : 'Detach'}</p>
                    <div
                        className={classNames({
                            'rounded-2xl px-4 py-3.5 mb-8': true,
                            'bg-green-500/5': plan?.status === 'ACTIVE',
                            'bg-red-500/5': plan?.status === 'INACTIVE',
                        })}
                    >
                        <p className='font-medium text mb-1'>{plan?.name}</p>
                        <p className='text-sm text-gray-500 dark:text-gray-400 line-clamp-3'>{plan?.description}</p>
                    </div>
                </div>


                <div className="mb-8">
                    <label htmlFor="input-name" className='block text-sm mb-2'>
                        {action === 'ATTACH' ? 'To' : 'From'} School
                    </label>
                    <SelectInput
                        items={schoolsQuery.data?.data || []}
                        labelKey='name'
                        valueKey='id'
                        searchable={true}
                        placeholder="Select school"
                        value={selectedSchool}
                        onChange={(val: any) => setSelectedSchool(val?.id)}
                        onSearch={(query) => debouncedSearchTerm(query)}
                        //onSearch={(query) => setSearchQuery(query)}
                        searching={schoolsQuery.isFetching}
                    />
                    {!selectedSchool && selectSchoolError && <p className='text-sm text-red-500 mt-2'>Select a school to continue</p>}
                </div>


                {
                    (clientQuery.error || saveMutation.error) &&
                    <p className='text-red-500'>
                        Sorry, an error occurred while {action === 'ATTACH' ? 'attaching' : 'detaching'} School school
                    </p>
                }

                <div className='flex justify-end items-center mt-10'>
                    <Button type='button' onClick={onClose} className='!text-gray-500 dark:!text-white bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 py-2.5 mr-6'>
                        Cancel
                    </Button>

                    <Button type='button' onClick={handleSubmit} className='' loading={saveMutation.isPending || clientQuery.isFetching} disabled={saveMutation.isPending || clientQuery.isFetching}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

interface Props {
    open: boolean;
    plan: any;
    action: 'ATTACH' | 'DETACH';
    onClose: () => void;
    onAttached?: (data: any) => void;
    onDetached?: (data: any) => void;
}

export default ModalAttachSchoolToPlan;