import { useFormikContext } from 'formik';
import { useEffect } from 'react';


function AutoSubmitForm({ enable = true }: Props) {
    const { values, submitForm } = useFormikContext();

    useEffect(() => {
        if (enable) {
            submitForm();
        }
    }, [values, submitForm]);


    return null;
}


interface Props {
    enable?: boolean;
}

export default AutoSubmitForm;