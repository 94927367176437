import React, { useState } from 'react';
import useToast from '../../../../../../../../../../state/hooks/useToast';
import ModalMaximumNegativeTransactionThresholdForm from '../../../modals/ModalMaximumNegativeTransactionThresholdForm';


function ConfigMaxNegativeTransactionSection({ configuration, school, client, setConfiguration }: Props) {
    const { addToast } = useToast();
    const [maxNegativeTransactionForm, setMaxNegativeTransactionForm] = useState(false);


    return (
        <React.Fragment>
            <div className='py-8'>
                <div className='flex flex-wrap gap-x-3 gap-y-1 mb-3'>
                    <h3 className='text-lg font-medium'>Maximum Negative Transactions Threshold</h3>
                    <button type='button' onClick={() => setMaxNegativeTransactionForm(true)} className='text-blue hover:underline'>Change</button>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                    <p className='flex items-center gap-x-2'>
                        <span className='font-medium'>GHS {configuration?.maximumNegativeTransactionThreshold?.toFixed(2)} set </span>
                        {(!configuration?.maximumNegativeTransactionThresholdDirectoryIds || configuration?.maximumNegativeTransactionThresholdDirectoryIds?.length === 0) && "For Every student"}
                        {configuration?.maximumNegativeTransactionThresholdDirectoryIds?.length === 1 && "For 1 Campus"}
                        {configuration?.maximumNegativeTransactionThresholdDirectoryIds?.length > 1 && `${configuration?.maximumNegativeTransactionThresholdDirectoryIds.length} For Campuses`}
                    </p>
                </div>
            </div>


            <ModalMaximumNegativeTransactionThresholdForm
                isOpen={maxNegativeTransactionForm}
                school={school}
                client={client}
                paymentConfig={configuration}
                onRequirementChanged={(config) => {
                    setConfiguration(config);
                    addToast({
                        text: "Maximum negative transaction threshold successfully updated",
                        type: "success"
                    });
                }}
                onClose={() => setMaxNegativeTransactionForm(false)}
            />
        </React.Fragment>
    );
}


interface Props {
    configuration: any;
    school: any;
    client: any;
    setConfiguration: (config: any) => void;
}

export default ConfigMaxNegativeTransactionSection;