import React, { useState } from 'react';
import ModalRequirePaymentAuthorizationForm from '../../../modals/ModalRequirePaymentAuthorizationForm';
import useToast from '../../../../../../../../../../state/hooks/useToast';


function ConfigRequirePaymentAuthorizationSection({ configuration, school, client, setConfiguration }: Props) {
    const { addToast } = useToast();
    const [requireAuthorizationForm, setRequireAuthorizationForm] = useState(false);


    return (
        <React.Fragment>
            <div className='py-8'>
                <div className='flex flex-wrap gap-x-3 gap-y-1 mb-3'>
                    <h3 className='text-lg font-medium'>Require Payment Authorization</h3>
                    <button type='button' onClick={() => setRequireAuthorizationForm(true)} className='text-blue hover:underline'>Change</button>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
                    <p className='flex items-center gap-x-2'>
                        Turned
                        {configuration.paymentAuthorizationRequired && <span className='inline-block text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                        {!configuration.paymentAuthorizationRequired && <span className='inline-block text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                        {(!configuration?.paymentAuthorizationRequiredDirectoryIds || configuration?.paymentAuthorizationRequiredDirectoryIds?.length === 0) && "For Every student"}
                        {configuration?.paymentAuthorizationRequiredDirectoryIds?.length === 1 && "For 1 Campus"}
                        {configuration?.paymentAuthorizationRequiredDirectoryIds?.length > 1 && `${configuration?.paymentAuthorizationRequiredDirectoryIds.length} For Campuses`}
                    </p>

                    {/*  <div className=''>
                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>For the School</p>
                <p className='flex items-center gap-x-2'>
                    Turned
                    {configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-2'>On</span>}
                    {!configuration.paymentAuthorizationRequired && <span className='inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-2'>Off</span>}
                </p>
            </div> */}
                    {/*  <div className=''>
                <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Campuses</p>
                <p>{profile?.configuration?.amPm ? '12 Hour' : '24 Hour'}</p>
            </div> */}
                </div>
            </div>


            {
                configuration &&
                <ModalRequirePaymentAuthorizationForm
                    isOpen={requireAuthorizationForm}
                    school={school}
                    client={client}
                    paymentConfig={configuration}
                    onRequirementChanged={(config) => {
                        setConfiguration(config);
                        addToast({
                            text: "Payment Authorization Requirement successfully updated",
                            type: "success"
                        });
                    }}
                    onClose={() => setRequireAuthorizationForm(false)}
                />
            }
        </React.Fragment>
    );
}


interface Props {
    configuration: any;
    school: any;
    client: any;
    setConfiguration: (config: any) => void;
}

export default ConfigRequirePaymentAuthorizationSection;